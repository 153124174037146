import React from "react";
import "./404.css";

export const NotFound = () => {
  return (
    <div className="notFound">
      <h1>404</h1>
      <p>
        Страница не найдена <span>😢</span>
      </p>
    </div>
  );
};
